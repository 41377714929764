/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;


/* LANDING PAGE */
.gradient-main-violate {
  background: linear-gradient(200deg, #202b60 41.6%, #63099A);
}

.landing-heropage-container {
  background: linear-gradient(180deg, #FFF 24.48%, #E8BFFF 73.44%, #D894FF 82.29%, #C35BFF 90.1%, #B638FF 100%);
}

.landing-heropage2-container {
  background: linear-gradient(180deg, #FFF 24.48%, #ffebbf 73.44%, #f8ff94 82.29%, #f8ff94 90.1%, #f8ff94 100%);
}

.landing-heropage3-container {
  background: var(--Radial, radial-gradient(48.35% 583.01% at 100% 0%, #A14DC6 0%, #5518BE 100%));
}

.box-shadow-1 {
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
}

body {
  font-family: "Manrope";
  background-color: #f6e8ff;
  width: 100%;
}

.card {
  /* white */
  background: #FFFFFF;
  /* gray/100 */

  border: 1px solid #F3F4F6;
  border-radius: 8px;
  padding: 24px;
}

.card-mini {
  /* white */
  background: #FFFFFF;
  /* gray/100 */

  border: 1px solid #F3F4F6;
  border-radius: 8px;
  padding: 16px;
}

input[type="text"] {
  font-size: 14px;
  font-family: "Manrope";
}

input[type="email"] {
  font-size: 14px;
  font-family: "Manrope";
}

.profile-image {
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

/* REACT SELECT 2 */
.select__menu {
  font-size: 14px;
  font-family: "Manrope" !important;
  padding: 0 !important;
  z-index: 999 !important;
}

.select__control {
  font-size: 14px;
  font-family: "Manrope" !important;
  color: #4B5563 !important;
  border: none !important;
  padding: 0 !important;
  outline: 'none' !important;
  /* z-index: 999!important; */
}

.select__control--is-focused {
  border: none !important;
  box-shadow: none !important;
  outline: 'none' !important;
}

.select__input:focus {
  border-color: transparent;
  /* Menghapus border warna biru pada fokus */
  box-shadow: none;
  /* Menghapus shadow pada fokus */
  cursor: pointer;
}

.no-select {
  user-select: none;
}

.input-error-message {
  color: #CB3A31;
  font-size: small;
}

.input-error-message::before {
  content: "• "
}

/* zzz */
.parallax-container {
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.parallax-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(242.55deg, rgba(143, 3, 237, 0.7) 0%, rgba(85, 33, 181, 0.7) 99.39%), url("./Assets/bgAuth.webp");
  background-size: cover;
  background-repeat: no-repeat;
  transform: translateZ(0);
  /* Trigger hardware acceleration for smoother animation */
}

.parallax-content {
  position: relative;
  z-index: 1;
  /* Make sure the content is on top of the background */
}

.sidebar>div {
  padding: 0;
}

.sidebar-item.active {
  background-color: #FBF3FF;
}

.sidebar-item.active * {
  color: #7A08C3;
}

/* BUTTON */
button {
  transition: all 60ms ease-in-out !important;
}

/* CARD ASSESSMENT */
.card-assessment {
  border-radius: 8px;
  border: 1px solid var(--humata-orange-secondary-600-base, #E8750E);
}

.card-assessment.ongoing {
  border: 1px solid var(--humata-orange-secondary-600-base, #E8750E)
}

.card-assessment.ongoning>.header {
  background: linear-gradient(135deg, #C0590E 0%, #E8750E 49.13%, #F9AA3E 100%);
}

.card-assessment.default {
  border: 1px solid var(--humata-orange-secondary-600-base, #A412FF)
}

.card-assessment.default>.header {
  background: linear-gradient(134deg, #8F03ED 0%, #AC38F6 49.48%, #C96FFF 100%);
}

.card-assessment.finished {
  border: 1px solid var(--humata-orange-secondary-600-base, #03543F)
}

.card-assessment.finished>.header {
  background: linear-gradient(135deg, #046C4E 0%, #31C48D 100%);
}

.dashboard-hero.default {
  background: radial-gradient(48.35% 583.01% at 100.00% 0.00%, #A14DC6 0%, #5518BE 100%);
}

.dashboard-hero.ongoing {
  background: radial-gradient(48.35% 583.01% at 100.00% 0.00%, #FACA15 0%, #E8750E 100%);
}

.dashboard-hero.finished {
  background: radial-gradient(48.35% 583.01% at 100.00% 0.00%, #31C48D 0%, #1A56DB 100%);
}

.suggestion-list {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: #fff;
}

.suggestion-item {
  padding: 8px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f1f1f1;
}

/* B2C */

.b2c.reports-knowledge {
  background: linear-gradient(92deg, #8F03ED -4.14%, #AC38F6 47.39%, #C96FFF 100%);
}

.b2c.reports-skills {
  background: linear-gradient(92deg, #C0590E 0%, #E8750E 49.13%, #F9AA3E 100%);
}

.b2c.reports-abilities {
  background: linear-gradient(92deg, #046C4E 0%, #31C48D 100%);
  ;
}

.b2c.reports-personalities {
  background: linear-gradient(92deg, #D61F69 -4.14%, #F17EB8 100%);
}

.b2c.reports-technology {
  background: linear-gradient(92deg, #1A56DB -4.14%, #76A9FA 100%);
}

.b2c.about-realistic {
  border-radius: 8px;
  background: linear-gradient(225deg, #3F83F8 0%, #1C64F2 38.02%, #6C2BD9 100%);
}

.b2c.about-investigative {
  border-radius: 8px;
  background: linear-gradient(225deg, #F7931F 0%, #E8750E 49.48%, #C81E1E 100%);
}

.b2c.about-artistics {
  border-radius: 8px;
  background: linear-gradient(225deg, #B638FF 0%, #A412FF 38.01%, #A412FF 38.02%, #5145CD 100%);
}

.b2c.about-socials {
  border-radius: 8px;
  background: linear-gradient(225deg, #F8B4B4 0%, #F05252 48.44%, #BF125D 100%);
}

.b2c.about-enterprising {
  border-radius: 8px;
  background: linear-gradient(225deg, #FACA15 0%, #E3A008 48.44%, #D61F69 100%);
}

.b2c.about-conventional {
  border-radius: 8px;
  background: linear-gradient(225deg, #16BDCA 0%, #0694A2 48.44%, #5145CD 100%);
}


/* TEXT LIMIT */
.limit-3 {
  line-height: 1.2em;
  height: 3.6em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.limit-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;

  /* max-height: 48px; */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@media print {
  div {
    break-inside: avoid;
  }
}